import React from "react";
import Box from "@mui/material/Box";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Grid, Typography, Button } from "@mui/material";
const Tarifas = () => {
  return (
    <Box>
      <Grid container justifyContent="center" alignItems="center" p={5}>
        <Grid xs={12} md={12} lg={6} textAlign="center">
          <Typography
            variant="h4"
            style={{ color: "#1e3d52", textAlign: "center" }}
          >
            TARIFAS DE INSCRIPCIÓN
          </Typography>
          <br />
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/1711997341723-trifas%20CORAIN%202024.jpg"
            loading="lazy"
            style={{ width: "75%" }}
          />
        </Grid>
        <Grid xs={12} md={12} lg={6}>
          <Typography
            variant="h4"
            style={{ color: "#1e3d52", textAlign: "center" }}
          >
            EL REGISTRO INCLUYE
          </Typography>
          <Typography
            variant="p"
            style={{ fontSize: "1em", textAlign: "justify" }}
          >
            <ul>
              <li>Conferencias</li>
              <li>Refrigerios a.m. y p.m.</li>
              <li>Almuerzo</li>
              <li>Acceso al área de stands</li>
              <li>Certificado de participación digital</li>
              <li>Tarifa especial a parqueo</li>
              <li>Grabación de conferencias</li>
            </ul>
          </Typography>
          <Button
            style={{
              backgroundColor: "#A60000",
              color: "white",
              border: "1px solid transparent",
            }}
            variant="outlined"
            startIcon={<DriveFileRenameOutlineIcon />}
            onClick={() => {
              window.open(
                "https://ebg.edu.gt/inscripcion/7d51622bb638d99fa7f6ddb1accc5998fafa5e77f35c144c397250f8f2ea4773",
                "_blank"
              );
            }}
          >
            Inscríbete en Línea
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Tarifas;

{
  /*<Box component="section" className="video-section">
          <Grid container></Grid>
          <video autoPlay muted loop id="myVideo">
            <source
              src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/RESUMEN+CLADIT+2023.mp4"
              type="video/mp4"
            />
          </video>
  </Box>*/
}
