import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Grid, Typography, Paper } from "@mui/material";
import Button from "@mui/material/Button";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import programa_json from "../../info/programa.json";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import InfoIcon from "@mui/icons-material/Info";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ViewPrograma from "../evento/programa/Programa";
const Programa = () => {
  return (
    <Box>
      <Stack spacing={4}>
        <Grid container>
          <Grid
            xs={12}
            md={6}
            lg={6}
            style={{ padding: "5%", textAlign: "justify" }}
          >
            <Stack spacing={4}>
              <Typography variant="h2">Programa Académico</Typography>
              <Typography variant="p" style={{ fontSize: "1.5em" }}>
                Para el CORAIN de este año, organizado por la Asociación
                Bancaria de Guatemala y entidades educativas, se ha elaborado un
                programa que aborda aspectos esenciales en el ámbito de la
                auditoría interna regional. El objetivo principal es
                proporcionar a los participantes una amplia variedad de
                conocimientos y herramientas para fortalecer sus estrategias de
                cumplimiento normativo y mejorar la eficiencia de sus procesos
                internos.
              </Typography>
              <Button
                startIcon={<PictureAsPdfIcon />}
                href="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Programa+CORAIN+2024+copy+(8).pdf"
                target="_blank"
              >
                Click para ver programa
              </Button>
            </Stack>
          </Grid>
          <Grid xs={12} md={6} lg={6} style={{ padding: "5%" }}>
            <img
              src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/WhatsApp+Image+2024-04-01+at+16.24.36.jpeg"
              loading="lazy"
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            paddingLeft: { lg: "5%", xs: "1%", md: "3%" },
            paddingRight: { lg: "5%", xs: "1%", md: "3%" },
          }}
        >
          <ViewPrograma
            speaker={false}
            hash="7d51622bb638d99fa7f6ddb1accc5998fafa5e77f35c144c397250f8f2ea4773"
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default Programa;