import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Typography, Grid } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
const s3Helper = require("../../helpers/S3.controller");
const helpers = require("../../helpers/helpers");
const Galeria2024 = () => {
  const [contador] = useState(0);
  const [files, setFiles] = useState([]);
  useEffect(() => {
    s3Helper
      .listFolderFilesUrl("GALERIA-CORAIN-2024")
      .then((files) => {
        setFiles(helpers.shuffleArray(files));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);
  return (
    <Box>
      <Stack
        spacing={4}
        style={{ padding: "5%" }}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h3" style={{ textAlign: "center" }}>
          GALERIA CORAIN 2024
        </Typography>
        <ImageList cols={2}  variant="woven">
          {files.map((actual, index) => (
            <ImageListItem>
              <img
                src={actual}
                alt={`img-galeria-cladit-2024-${index}`}
                loading="lazy"
                style={{ objectFit: "contain" }}
              />{" "}
            </ImageListItem>
          ))}
        </ImageList>
      </Stack>
    </Box>
  );
};

export default Galeria2024;
