import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import "./style.css";
import { Grid, Typography, Divider } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import Button from "@mui/material/Button";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import conferencistas_json from "../../info/conferencistas.json";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea, CardActions } from "@mui/material";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
const helpers = require("../../helpers/helpers");
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Inicio = () => {
  const [cambios, setCambios] = useState(0);
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const arr_expositores = helpers.chunkArray(
    conferencistas_json.conferencistas,
    4
  );
  const maxSteps = arr_expositores.length;

  useEffect(() => {}, [cambios]);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setCambios(cambios + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setCambios(cambios + 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
    setCambios(cambios + 1);
  };
  const handleTogglePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
    setCambios(cambios + 1);
  };
  return (
    <Box paddingTop={10}>
      <Stack spacing={4} alignItems="center">
        <Stack spacing={5} alignItems="center" justifyContent="center">
          <Typography variant="h4" style={{ textAlign: "center" }}>
            <EventIcon /> &nbsp;26 de ABRIL
          </Typography>
          <Button
            style={{
              backgroundColor: "#A60000",
              color: "white",
              border: "1px solid transparent",
            }}
            variant="outlined"
            startIcon={<DriveFileRenameOutlineIcon />}
            onClick={() => {
              window.open(
                "https://ebg.edu.gt/inscripcion/7d51622bb638d99fa7f6ddb1accc5998fafa5e77f35c144c397250f8f2ea4773",
                "_blank"
              );
            }}
          >
            Inscríbete en Línea
          </Button>
        </Stack>

        <video autoPlay muted loop style={{ width: "50%" }}>
          <source
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/1712110077622-1712097668.mp4"
            type="video/mp4"
          />
        </video>
        <Stack alignItems="center" justifyContent="center" spacing={5}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ textAlign: "justify" }}
          >
            <Grid xs={12} md={6} lg={6} p={3} style={{ textAlign: "center" }}>
              <img
                src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/1711997341723-trifas%20CORAIN%202024.jpg"
                style={{ width: "50%" }}
                alt="flyer-corain"
              />
            </Grid>
            <Grid xs={12} md={6} lg={6} p={3}>
              <Typography variant="h4">¿Qué es CORAIN?</Typography>
              <Typography variant="p">
                <br />
                El Congreso Regional de Auditoría Interna&nbsp;
                <strong>-CORAIN-</strong>&nbsp;representa una gran oportunidad
                para los profesionales de la auditoría interna, para
                actualizarse en las mejores prácticas internacionales sobre
                temas de:&nbsp;
                <strong>
                  Tecnología de información en Auditoría Interna, Normativas
                  Regulatorias, Continuidad del Negocio, Capital Humano,
                  Criterios ESG en la auditoría, entre otros.
                </strong>
                &nbsp;Nos acompañarán conferencistas nacionales e
                internacionales; de reconocido prestigio especialistas en los
                temas a exponer, así también, estrechar lazos de amistad con
                colegas y amigos o establecer relaciones profesionales y
                comerciales.
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="center">
            <Grid xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
              <Typography variant="h4">HOTEL BARCELÓ</Typography>
              <Typography variant="h5">Salón las Naciones</Typography>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.025167691274!2d-90.5215387242587!3d14.597641777147818!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a3da4d796741%3A0x67a03ec10722b685!2sBarcel%C3%B3%20Guatemala%20City!5e0!3m2!1ses!2sgt!4v1711994358472!5m2!1ses!2sgt"
                width="75%"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Grid>
            <Grid xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
              <img
                src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/BGUA_VIEW_01.jpg"
                style={{ width: "60%", loading: "lazy" }}
              />
            </Grid>
          </Grid>
          <ImageList cols={5}>
            <ImageListItem>
              <img
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/5b1900b9-b7ac-4159-b489-0bb2ac1f03a1.jpg`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/01733751_gp.jpg`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/BGUA_WED_29.webp`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/BGC%2B-%2BEL%2BDORADO%2BMONTAJE.jpg`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/129852.jpg`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
          </ImageList>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="h3" style={{ textAlign: "center" }}>
            Patrocinadores
          </Typography>
          <Divider style={{ backgroundColor: "#A60000", height: "5px" }} />
          <Typography variant="h4" style={{ textAlign: "center" }}>
            Top Diamante
          </Typography>
          <ImageList cols={10}>
            <ImageListItem></ImageListItem>
            <ImageListItem></ImageListItem>
            <ImageListItem></ImageListItem>
            <ImageListItem></ImageListItem>
            <ImageListItem cols={2}>
              <img
                style={{ width: "100%" }}
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/1+BANRURAL.png`}
                alt="corain-img"
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem></ImageListItem>
            <ImageListItem></ImageListItem>
            <ImageListItem></ImageListItem>
            <ImageListItem></ImageListItem>
          </ImageList>
          <Divider style={{ backgroundColor: "#A60000", height: "5px" }} />
          <Typography variant="h4" style={{ textAlign: "center" }}>
            Oro
          </Typography>
          <ImageList cols={10}>
            <ImageListItem></ImageListItem>
            <ImageListItem></ImageListItem>
            <ImageListItem></ImageListItem>
            <ImageListItem cols={2} style={{ padding: "5%" }}>
              <img
                style={{ width: "100%" }}
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/1683312986323-ES.png`}
                alt="corain-img"
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem cols={2} style={{ padding: "5%" }}>
              <img
                style={{ width: "50%", objectFit: "contain" }}
                src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/Logo+Digital+SESO+Exacto+Vertical+HR+no+background.png`}
                alt="corain-img"
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem></ImageListItem>
            <ImageListItem></ImageListItem>
            <ImageListItem></ImageListItem>
          </ImageList>
          <Divider style={{ backgroundColor: "#A60000", height: "5px" }} />
        </Stack>
        <Typography variant="h3" style={{ textAlign: "center" }}>
          Conferencistas
        </Typography>
        <Grid container justifyContent="center" alignItems="center">
          <Grid xs={12} md={12} lg={12}>
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              interval={5000}
            >
              {arr_expositores.map((actual, index) => (
                <Box
                  alignItems="center"
                  justifyContent="center"
                  key={index}
                  style={{ width: "100%" }}
                >
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="stretch"
                    >
                      {actual.map((speaker, indice) => (
                        <Grid
                          xs={6}
                          md={6}
                          lg={3}
                          item
                          key={`speaker-${indice}`}
                          style={{ display: "flex" }}
                          p={5}
                        >
                          <Card
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <CardMedia
                              component="img"
                              style={{
                                width: "100%",
                                height: "140px",
                                objectFit: "contain",
                                alignSelf: "flex-start", // Align photo to the top
                              }}
                              image={speaker.foto}
                              alt="conferencista-cladit"
                            />

                            <CardContent
                              style={{
                                alignSelf: "flex-start",
                                textAlign: "center",
                                width: "100%",
                              }}
                            >
                              <Stack alignItems="center">
                                {speaker.bandera && (
                                  <img
                                    src={speaker.bandera}
                                    alt="bandera-speaker"
                                    style={{
                                      width: "25%",
                                      height: "35px",
                                      objectFit: "contain",
                                    }}
                                  />
                                )}
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                >
                                  {speaker.nombre}
                                </Typography>
                                <Typography
                                  variant="body"
                                  color="text.secondary"
                                >
                                  {speaker.puesto}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {speaker.institucion}
                                </Typography>
                                <Typography
                                  variant="body3"
                                  color="text.secondary"
                                >
                                  {speaker.pais}
                                </Typography>
                              </Stack>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  ) : null}
                </Box>
              ))}
            </AutoPlaySwipeableViews>
          </Grid>
          <Grid xs={12} md={12} lg={12} style={{ width: "100%" }}>
            <MobileStepper
              style={{ width: "100%" }}
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                </Button>
              }
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="center">
          <Grid xs={12} md={6} lg={6}>
            <img
              style={{ width: "100%" }}
              src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/WhatsApp+Image+2024-04-01+at+16.03.58.jpeg`}
              alt="corain-img"
              loading="lazy"
            />
          </Grid>
          <Grid xs={12} md={6} lg={6}>
            <Stack spacing={2} style={{ padding: "6%" }}>
              <Typography variant="h4" style={{ textAlign: "center" }}>
                Dirigido a:
              </Typography>
              <Typography
                variant="p"
                style={{ textAlign: "justify", fontSize: "1.5em" }}
              >
                <ul>
                  <li>Auditor interno</li>
                  <li>externo</li>
                  <li>contador</li>
                  <li>riesgo</li>
                  <li>cumplimiento </li>
                  <li>financista</li>
                  <li>tecnología</li>
                </ul>
                <br />
                Con el propósito de dar a conocer la importancia de la gestión
                de un auditor interno en las empresas y la importancia de la
                actualización tecnológica.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default Inicio;

{
  /*<Box component="section" className="video-section">
          <Grid container></Grid>
          <video autoPlay muted loop id="myVideo">
            <source
              src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/RESUMEN+CLADIT+2023.mp4"
              type="video/mp4"
            />
          </video>
  </Box>*/
}
